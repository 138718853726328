@charset "UTF-8";

/*@/contents/corp/tl-cardnetj_w01/ssl/htdocs/usr-data/template/FOR-CN_75/css/index/index.css*/

.breadcrumb_clone {
  display: none;
}

.index_section {
  padding-bottom: 54px;
  background: #dcdcdc;

  > {
    *:first-child {
      margin-top: 0 !important;
    }

    .inner {
      width: 990px;
      margin-right: auto;
      margin-left: auto;
    }
  }
}

.ttl01 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 134px;
  background: #fff;
  font-size: 2.7rem;
  font-weight: bold;
}

h2[class*='ttl'] + {
  * {
    margin-top: 56px;
  }

  .layoutbox02 {
    margin-top: 0;
  }
}

.layoutbox01 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;

  > li {
    display: block;
    width: calc((100% - 26px) / 2);
    margin-left: 26px;
    background: #fff;

    &:nth-of-type(2n+1) {
      margin-left: 0;
    }

    &:nth-of-type(n+3) {
      margin-top: 26px;
    }

    .img img {
      width: 100%;
    }

    .detail {
      padding: 20px;

      .ttl {
        font-size: 1.8rem;
        font-weight: bold;
      }

      .txt {
        margin-top: 16px;
        font-size: 1.2rem;
      }

      .service {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-top: 20px;

        > li {
          width: calc((99.9% - 16px) / 3);

          + li {
            margin-left: 8px;
          }

          &:nth-of-type(3n+1) {
            margin-left: 0;
          }

          &:nth-of-type(n+4) {
            margin-top: 8px;
          }

          a {
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -webkit-align-items: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -webkit-justify-content: center;
            -ms-flex-pack: center;
            justify-content: center;
            height: 100%;
            padding: 6px;
            background: #0068b7;
            color: #fff;
            font-size: 1.04rem;
            font-weight: bold;
            text-align: center;
            text-decoration: none;
            -webkit-transition: opacity .3s ease-in;
            -o-transition: opacity .3s ease-in;
            transition: opacity .3s ease-in;

            &:hover {
              opacity: .7;
            }
          }
        }
      }
    }
  }
}

.layoutbox02 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;

  > li {
    position: relative;
    width: 50%;

    .img img {
      width: 100%;
      height: auto;
    }

    .txtbox {
      position: absolute;
      top: 26px;
      left: 56px;

      .ttl {
        font-size: 1.8rem;
        font-weight: bold;
      }

      .txt {
        margin-top: 16px;
        font-size: 1.2rem;
        font-weight: bold;
      }

      .btn {
        display: inline-block;
        margin-top: 18px;
        padding: 8px;
        background: #0068b7;
        color: #fff;
        font-size: 1.8rem;
        text-decoration: none;
        -webkit-transition: opacity .3s ease-in;
        -o-transition: opacity .3s ease-in;
        transition: opacity .3s ease-in;

        img {
          width: auto;
          height: 12px;
        }

        &:hover {
          opacity: .7;
        }

        span {
          display: inline-block;
          position: relative;
          padding-right: 16px;

          &:after {
            position: absolute;
            right: 2px;
            bottom: 1px;
            width: 8px;
            height: 8px;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
            border-top: 2px solid #fff;
            border-right: 2px solid #fff;
            content: '';
          }
        }
      }
    }
  }
}

.layoutbox03 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;

  > div {
    width: calc((99.9% - 26px) / 2);
    background: #fff;

    + div {
      margin-left: 26px;
    }

    &.newsbox {
      padding: 0 20px 20px 20px;

      .ttl {
        padding-top: 20px;
        font-size: 2.6rem;
        font-weight: bold;
      }

      .news {
        margin-top: 12px;

        > li {
          border-bottom: 1px solid #0068b7;

          &:first-of-type {
            border-top: 1px solid #0068b7;
          }

          a {
            display: block;
            position: relative;
            padding: 16px 0;
            font-size: 1.2rem;
            text-decoration: none;
            -webkit-transition: opacity .3s ease-in;
            -o-transition: opacity .3s ease-in;
            transition: opacity .3s ease-in;

            &:hover {
              opacity: .7;
            }

            .date {
              display: block;
              position: absolute;
              top: 16px;
              left: 0;
              color: #1071bc;
            }

            .txt {
              display: block;
              margin-left: 7em;
              color: #231815;
            }

            .icon_pdf {
              margin-left: 4px;

              img {
                width: 15px;
                height: auto;
              }
            }

            .icon_new {
              display: inline-block;
              margin-left: 5px;
              padding: 1px 10px;
              background: #e60012;
              color: #fff;
              font-size: 1rem;
              font-weight: bold;
            }
          }
        }
      }
    }

    &.linkbox {
      .img {
        position: relative;
        overflow: hidden;

        img {
          position: absolute;
          top: 50%;
          left: 50%;
          width: auto;
          height: 100%;
          -webkit-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
        }
      }

      .detail {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between;
        margin-top: 18px;
        padding: 0 20px;

        .txt {
          font-size: 1.2rem;
          font-weight: bold;
        }
      }
    }
  }

  .btn_wrapper {
    margin-top: 18px;
    text-align: right;
  }

  .btn {
    display: inline-block;
    padding: 8px;
    background: #0068b7;
    color: #fff;
    font-size: 1.6rem;
    text-decoration: none;
    -webkit-transition: opacity .3s ease-in;
    -o-transition: opacity .3s ease-in;
    transition: opacity .3s ease-in;

    img {
      width: auto;
      height: 12px;
    }

    &:hover {
      opacity: .7;
    }

    span {
      display: inline-block;
      position: relative;
      padding-right: 16px;

      &:after {
        position: absolute;
        right: 2px;
        bottom: 1px;
        width: 8px;
        height: 8px;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        border-top: 2px solid #fff;
        border-right: 2px solid #fff;
        content: '';
      }
    }
  }
}

.layoutbox04 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 56px;
  padding: 40px 115px;
  background: #fff;

  .box01 {
    width: 160px;

    a {
      -webkit-transition: opacity .3s ease-in;
      -o-transition: opacity .3s ease-in;
      transition: opacity .3s ease-in;

      &:hover {
        opacity: .7;
      }

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .box02 {
    text-align: center;

    .ttl {
      font-size: 1.8rem;
      font-weight: bold;
    }

    .txt {
      margin-top: 12px;
      font-size: 1.2rem;
      text-align: left;
    }
  }
}

.sp_sub_header {
  display: none;

  .link01 {
    display: table;
    width: 100%;
    border-bottom: 1px solid #fff;

    > a {
      display: table-cell;
      width: 50%;
      height: 54px;
      padding: 8px 4px;
      color: #fff;
      font-size: 1.2rem;
      text-align: center;
      text-decoration: none;
      vertical-align: middle;

      > span {
        display: inline-block;
        position: relative;
        padding-left: 16px;

        &:before {
          display: block;
          position: absolute;
          top: 50%;
          left: 0;
          width: 0;
          height: 0;
          margin-top: -5px;
          border-width: 5px 0 5px 7px;
          border-style: solid;
          border-color: transparent transparent transparent #fff;
          content: '';
        }
      }

      &:first-of-type {
        border-right: 1px solid #fff;
      }
    }
  }
}

.index_section {
  &.mv {
    padding: 0;
    background: #fff;

    .mv_inner {
      position: relative;
      overflow: hidden;

      .mv_slider {
        .slick-track {
          overflow: hidden;
        }

        .slick-slide {
          position: relative;
          height: 700px;
          float: left;
          overflow: hidden;

          img.bg {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: auto;
            -webkit-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
          }

          .txtbox01 {
            position: absolute;
            top: 206px;
            right: 0;
            left: 0;
            text-align: center;
          }

          .txtbox02 {
            position: absolute;
            right: 0;
            bottom: 55px;
            left: 0;

            .inner {
              width: 950px;
              margin: 0 auto;

              .view_more {
                margin-top: 80px;
                text-align: center;

                .txt {
                  font-size: 2.1rem;
                  font-weight: bold;
                }

                .btn {
                  display: -webkit-inline-box;
                  display: -webkit-inline-flex;
                  display: -ms-inline-flexbox;
                  display: inline-flex;
                  position: relative;
                  -webkit-box-align: center;
                  -webkit-align-items: center;
                  -ms-flex-align: center;
                  align-items: center;
                  height: 40px;
                  margin-top: 20px;
                  padding: 0 32px 0 15px;
                  background: #0068b7;
                  color: #fff;
                  font-size: 2.6rem;
                  text-decoration: none;
                  -webkit-transition: opacity .3s ease-in;
                  -o-transition: opacity .3s ease-in;
                  transition: opacity .3s ease-in;

                  img {
                    width: auto;
                    height: 18px;
                  }

                  &:before {
                    -webkit-transform: rotate(45deg);
                    position: absolute;
                    right: 8px;
                    bottom: 13px;
                    width: 12px;
                    height: 12px;
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                    border-top: 2px solid #fff;
                    border-right: 2px solid #fff;
                    content: '';
                  }

                  &:after {
                    position: absolute;
                    top: 0;
                    left: 100%;
                    width: 0;
                    height: 0;
                    border-width: 20px 0 20px 20px;
                    border-style: solid;
                    border-color: transparent transparent transparent #0068b7;
                    content: '';
                  }

                  &:hover {
                    opacity: .7;
                  }
                }
              }
            }
          }
        }
      }

      .slick-prev, .slick-next {
        z-index: 2;
        position: absolute;
        top: 50%;
        width: 60px;
        height: 60px;
        font-size: 0;
        cursor: pointer;
        -webkit-transition: opacity .3s ease-in;
        -o-transition: opacity .3s ease-in;
        transition: opacity .3s ease-in;
      }

      .slick-prev:before, .slick-next:before {
        position: absolute;
        top: 50%;
        width: 34px;
        height: 34px;
        margin-top: -17px;
        border-top: 2px solid #000;
        border-right: 2px solid #000;
        content: '';
      }

      .slick-prev:hover, .slick-next:hover {
        opacity: .5;
      }

      .slick-prev {
        left: 10px;

        &:before {
          right: 2px;
          -webkit-transform: rotate(-135deg);
          -ms-transform: rotate(-135deg);
          transform: rotate(-135deg);
        }
      }

      .slick-next {
        right: 10px;

        &:before {
          left: 2px;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      }
    }
  }

  &.important {
    padding: 30px 0;
    background: #eee;

    > .inner {
      padding: 14px;
      border: 1px solid #e60012;

      > {
        .icon {
          display: inline-block;
          padding: 6px 24px;
          background: #e60012;
          color: #fff;
          font-weight: bold;

          + .ttl {
            margin-top: 10px;
          }
        }

        .ttl {
          margin-top: 25px;
          color: #e60012;
          font-weight: bold;
        }
      }

      .acdn_box01 {
        .head {
          position: relative;
          margin-top: 10px;
          padding: 0 30px 6px 0;
          border-bottom: 1px solid #e60012;
          cursor: pointer;
          -webkit-transition: opacity .3s ease-in;
          -o-transition: opacity .3s ease-in;
          transition: opacity .3s ease-in;

          &:hover {
            opacity: .7;
          }

          &:after {
            position: absolute;
            right: 12px;
            bottom: 10px;
            width: 7px;
            height: 7px;
            margin-top: -17px;
            -webkit-transform: rotate(135deg);
            -ms-transform: rotate(135deg);
            transform: rotate(135deg);
            border-top: 2px solid #e60012;
            border-right: 2px solid #e60012;
            content: '';
          }

          &.active:after {
            -webkit-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
            transform: rotate(-45deg);
          }

          .date {
            position: absolute;
            top: 0;
            left: 0;
            color: #848282;
          }

          .txt {
            display: block;
            margin-left: 5.5em;
            color: #231815;
            text-decoration: none;
          }
        }

        .content {
          display: none;
          padding-top: 12px;

          .close {
            margin-top: 10px;
            padding: 6px;
            background: #bcbcbc;
            color: #fff;
            text-align: center;
            cursor: pointer;
            -webkit-transition: opacity .3s ease-in;
            -o-transition: opacity .3s ease-in;
            transition: opacity .3s ease-in;

            &:hover {
              opacity: .7;
            }
          }
        }
      }
    }
  }

  &.service {
    padding-bottom: 0;
  }
}

@media screen and (max-width: 766px) {
  .index_section {
    padding: 0 20px 60px;

    > .inner {
      width: 100%;
      margin-top: 20px;
    }
  }

  .ttl01 {
    display: block;
    height: auto;
    margin-right: -20px;
    margin-left: -20px;
    padding: 29px 20px;
    font-size: 1.8rem;
    text-align: center;
  }

  .layoutbox01 > li {
    width: 100%;
    margin-top: 20px;
    margin-left: 0;

    &:first-of-type {
      margin-top: 0;
    }

    &:nth-of-type(n+3) {
      margin-top: 20px;
    }

    .detail {
      padding: 16px 10px;

      .txt {
        margin-top: 12px;
        font-size: 1.4rem;
      }

      .service {
        margin-top: 14px;

        > li {
          width: calc((99.9% - 11px) / 2);

          + li, &:nth-of-type(3n+1) {
            margin-left: 11px;
          }

          &:nth-of-type(2n+1) {
            margin-left: 0;
          }

          &:nth-of-type(n+3) {
            margin-top: 16px;
          }

          a {
            padding: 6px 2px;
            font-size: 1rem;
            white-space: nowrap;

            &:hover {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  .layoutbox02 {
    display: block;
    margin: 0 -20px;

    > li {
      width: 100%;

      .img img {
        opacity: .4;
      }

      .txtbox {
        top: 18px;
        left: 20px;

        .txt {
          margin-top: 14px;
          font-size: 1.4rem;
        }

        .btn {
          margin-top: 12px;
          padding: 14px 30px;
          font-size: 1.6rem;

          &:hover {
            opacity: 1;
          }

          span:after {
            bottom: 2px;
            width: 7px;
            height: 7px;
          }
        }
      }
    }
  }

  .layoutbox03 {
    display: block;

    > div {
      width: 100%;

      + div {
        margin-top: 32px;
        margin-left: 0;
      }

      &.newsbox {
        padding: 0 14px 20px;

        .ttl {
          padding-top: 14px;
          font-size: 2rem;
        }

        .news {
          margin-top: 14px;

          > li a {
            padding: 8px 0;
            font-size: 1.4rem;

            &:hover {
              opacity: 1;
            }

            .date {
              top: 8px;
            }

            .txt {
              margin-left: 7.5em;
            }

            .icon_pdf img {
              width: 20px;
            }

            .icon_new {
              font-size: 1.2rem;
            }
          }
        }
      }

      &.linkbox {
        .img {
          position: static;

          img {
            position: static;
            width: 100%;
            height: auto;
            -webkit-transform: none;
            -ms-transform: none;
            transform: none;
          }
        }

        .detail {
          display: block;
          padding-bottom: 20px;
          text-align: center;

          .txt {
            font-size: 1.6rem;

            + .btn {
              margin-top: 20px;
            }
          }
        }
      }
    }

    .btn_wrapper {
      margin-top: 20px;
      text-align: center;
    }

    .btn {
      padding: 14px 30px;

      &:hover {
        opacity: 1;
      }

      span:after {
        bottom: 2px;
        width: 7px;
        height: 7px;
      }
    }

    .auto_h {
      height: auto !important;
    }
  }

  .layoutbox04 {
    display: block;
    margin-top: 32px;
    padding: 20px 25px;

    .box01 {
      width: 100%;
      text-align: center;

      a {
        display: inline-block;
        max-width: 155px;

        &:hover {
          opacity: 1;
        }
      }
    }

    .box02 {
      .ttl {
        margin-top: 18px;
        font-size: 1.6rem;
      }

      .txt {
        font-size: 1.4rem;
      }
    }
  }

  .header .fix_area:before {
    display: none;
  }

  .sp_sub_header {
    display: block;
    background: #0068b7;
  }

  .index_section {
    &.mv .mv_inner {
      .mv_slider .slick-slide {
        height: calc(100vh - 122px);

        img.bg {
          width: auto;
          height: 102%;
        }

        .txtbox01 {
          position: absolute;
          top: 23%;
          -webkit-transform: none;
          -ms-transform: none;
          transform: none;

          .main_copy img {
            width: 80%;
            max-width: 100%;
          }
        }

        .txtbox02 {
          bottom: 16.5%;

          .inner {
            width: 100%;

            .topics {
              height: 42vh;
              margin: 0 auto;
              text-align: center;

              img {
                width: auto;
                height: 100%;
              }
            }

            .view_more {
              margin-top: 2%;

              .txt {
                font-size: 1.5rem;
              }

              .btn {
                height: 44px;
                margin-top: 2%;
                margin-right: 19px;
                padding: 0 31px 0 34px;

                img {
                  height: 12px;
                }

                &:before {
                  right: 12px;
                  bottom: 17px;
                  width: 7px;
                  height: 7px;
                }

                &:after {
                  border-width: 22px 0 22px 19px;
                }
              }
            }
          }
        }
      }

      .slick-prev, .slick-next {
        margin-top: -30px;
      }

      .slick-prev:before, .slick-next:before {
        width: 25px;
        height: 25px;
        margin-top: -13px;
      }

      .slick-prev:hover, .slick-next:hover {
        opacity: 1;
      }

      .slick-prev {
        left: 3px;

        &:before {
          right: 10px;
        }
      }

      .slick-next {
        right: 3px;

        &:before {
          left: 10px;
        }
      }
    }

    &.important {
      padding: 20px;

      > .inner .acdn_box01 {
        font-size: 1.4rem;

        .head:hover {
          opacity: 1;
        }

        .content .close {
          font-size: 1.6rem;

          &:hover {
            opacity: 1;
          }
        }
      }
    }

    &.topics {
      padding: 0 20px 80px;

      > .inner {
        margin-top: 32px;
      }
    }
  }
}
